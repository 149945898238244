* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: none;
}

:root {
  --fs-400: clamp(3rem, 12vw + 1rem, 6.5rem);
  --fs-600: clamp(2rem, 6vw + 1rem, 4rem);
  --fs-800: clamp(2rem, 6vw + 0.5rem, 3rem);
  --fs-900: clamp(1.5rem, 4vw + 0.5rem, 1.75rem);
  --fs-1000: clamp(1rem, 1vw + 0.25rem, 1.5rem);
  --fs-1200: clamp(0.75rem, 1vw + 0.25rem, 1rem);
  --font: "Montserrat", sans-serif;
  --secondary-dark: #ed6a5aff;
  --secondary: #f4f1bbff;
  --primary-light: #9bc1bcff;
  --primary: #5ca4a9ff;
  --background: #e6ebe0ff;
  --bs: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  --toastify-color-info: #3498db;
  --toastify-color-success: #5ca4a9ff;
  --toastify-color-warning: #f4f1bbff;
  --toastify-color-error: #ed6a5aff;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
}

body {
  font-family: var(--font);
  font-size: var (--fs-600);
  -webkit-font-smoothing: subpixel-antialiased;
  width: 100%;
}

.background {
  background-image: url("./assets/brooke-lark-kXQ3J7_2fpc-unsplash.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: fixed;
  height: 100vh;
  z-index: -1;
  width: 100vw;
  -webkit-filter: brightness(50%);
  filter: brightness(50%);
}

.container {
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 2000px;
  position: relative;
}

.page-container {
  padding: 2rem;
  min-height: 100vh;
  display: flex;

  align-items: center;
  flex-direction: column;
}

.page-title {
  font-family: var(--font);
  font-size: var(--fs-800);
  margin-bottom: 2rem;
  color: var(--background);
  text-align: center;
}

button {
  font-family: inherit;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  border: none;
  border-radius: 5px;
  letter-spacing: 2px;
}

button:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

button:focus {
  outline: none;
}

h2 {
  font-family: montserrat, sans-serif;
}

h3 {
  font-size: var(--fs-1000);
}

li {
  font-family: Poppins, sans-serif;
}

ul > li {
  list-style-type: none;
}

.btn {
  padding: 1.2em 2em 1em 2em;
  font-weight: 600;
  text-transform: uppercase;
  font-size: var(--fs-1000);
  box-shadow: var(--bs);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar-container {
  width: 60%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.search-bar-container input {
  width: 100%;
  height: 60px;
  outline: none;
  border: none;
  padding-left: 20px;
  font-family: inherit;
  font-size: var(--fs-1000);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.search-bar-container button {
  width: 150px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  background-color: var(--secondary-dark);
}

.search-bar-container button:hover {
  transform: scale(1);
}

/***** Filter *****/
.filter-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  background-color: rgba(230, 235, 224, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem;
  padding-right: 10px;
}

.filter-container button {
  background-color: rgba(230, 235, 224, 0.5);

  padding: 0.5rem 1rem;
  border-radius: 40px;
  margin-left: 10px;
  font-size: var(--fs-1000);
}

.filter-container button:hover {
  background-color: white;
}

/***** Navbar *****/
.menu {
  display: flex;
  gap: 2rem;
  z-index: 3;
}

.menu-item {
  color: black;
}

.menu ul {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.resume {
  background-color: transparent;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 0.5rem 1.5rem;
  font-size: var(--fs-1000);
}

.mobile-menu-icon {
  z-index: 2;
  height: 50px;
  width: 50px;
  position: relative;
}

.mobile-menu-icon:hover {
  cursor: pointer;
}

.icon-div {
  z-index: 4;
}

.mobile-menu-container {
  background-color: var(--background);
  color: white;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  border-radius: 5px;
  width: 25%;
  height: 100vh;
}

.mobile-menu-item {
  font-size: var(--fs-900);
  font-weight: 400;
  color: black;
  list-style: none;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.mobile-menu-item p {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 70%;
  margin: 0 auto;
  text-wrap: nowrap;

  /* padding-left: 20%; */
}

.mobile-menu-item:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

.mobile-resume {
  background-color: transparent;
  color: black;
  border: 3px solid black;
  padding: 0.5rem 1.5rem;
  font-size: var(--fs-900);
  font-weight: 500;
}

.navbar-container {
  width: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: var(--fs-900);
  max-width: 2000px;
  width: 100%;
  color: white;
  z-index: 5;
  padding: 1rem 2rem;
  top: 0;
  margin: auto;
  background: transparent;
}

.link {
  text-decoration: none;
  color: black;
}

.logo-link {
  text-decoration: none;
  color: white;
}

.link:focus {
  outline: none;
}

/**** Search ****/
.search-container {
  margin: 0 auto;
  width: 70%;
  margin-bottom: 40px;
}

.ais-SearchBox-form {
  border-radius: 10px;
  height: 50px;
  overflow: hidden;
  font-size: var(--fs-1000);
}

.ais-Stats {
  text-align: center;
  margin-bottom: 20px;
  font-size: var(--fs-1000);
}

.ais-Hits-item {
  text-transform: capitalize;
  box-shadow: var(--bs);
  text-align: center;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
  height: 18rem;
  width: 12rem;
  border-radius: 15px;
  overflow: hidden;
  padding: 0;
}

.search-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.search-card-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 5px;
}

.ais-Hits-item:first-of-type,
.ais-Hits-item:last-of-type {
  border-radius: 15px;
}

.ais-Hits-item:hover {
  transform: translateY(-10px);
}

.ais-Hits {
  margin: 0 40px;
}

.ais-Hits-list {
  display: grid;
  gap: 2rem 0.5rem;
  height: 100%;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  width: 100%;
  border-radius: 15px;
  justify-items: center;
}

.search-content {
  background-color: rgba(230, 235, 224, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 15px;
  padding: 20px 0;
}

.search-main {
  height: 100%;
  width: 100%;
}

.search-content {
  height: 100%;
}

.ais-Pagination {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

/***** Main Page *****/
.header {
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url("./assets/brooke-lark-kXQ3J7_2fpc-unsplash.jpg") no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
}

.header-container {
  padding-bottom: 8rem;
}

.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh + 89px);
}

.header-content {
  font-size: var(--fs-800);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-bottom: 2rem;
  max-width: 2000px;
  transform: translateY(-150px);
}

.header-content h1 {
  font-size: var(--fs-400);
  text-transform: uppercase;
  font-weight: bold;
  color: white;
}

.header-content p {
  margin-bottom: 4rem;
}

.main-title {
  font-family: Josefin Sans, sans-serif;
  font-weight: 500;
  line-height: 0.8;
  margin-bottom: 4rem;
  max-width: 1000px;
  letter-spacing: 5px;
}

.kitchen {
  text-align: end;
  padding-left: 2rem;
}

/***** Categories Section on Homepage *****/
.category-section-header {
  background-color: var(--background);
}

.category-section-container {
  padding: 3rem 2rem 2rem 2rem;
  /* position: relative; */
  min-height: 400px;
  margin: auto;
}

.category-cards-container > * {
  display: flex;
  justify-content: center;
}

.category-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  justify-items: center;
  gap: 2rem 0.5rem;
  width: 100%;
}

.section-btn-container {
  display: flex;
  justify-content: center;
  margin: 4rem 0 0 0;
}

.section-btn {
  position: relative;
}

/***** Categories / Category *****/
.category-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  gap: 2rem 0.5rem;
  width: 100%;
  background-color: rgba(230, 235, 224, 0.7);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding: 2rem;
  border-radius: 15px;
  justify-items: center;
}

.category-header {
  text-align: center;
  margin-bottom: 4rem;
}

.categories-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
  gap: 2rem 0.5rem;
  width: 100%;
  background-color: rgba(230, 235, 224, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding: 2rem;
  border-radius: 15px;
  justify-items: center;
}

/***** Category Card *****/
.category-link {
  width: 100%;
}

.category-card {
  text-transform: capitalize;
  box-shadow: var(--bs);
  text-align: center;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
  height: 18rem;
  width: 12rem;
  border-radius: 15px;
  overflow: hidden;
}

.category-card-mobile {
  background-color: var(--background);
  text-transform: capitalize;
  box-shadow: var(--bs);
  position: relative;
  text-align: center;
  display: flex;
  line-height: 1.1;
  max-width: 400px;
  border-radius: 15px;
  flex-direction: row;
  font-size: 10px;
  height: 4rem;
}

.category-image-mobile {
  aspect-ratio: 1/1;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  flex: 1;
  border-radius: 15px 0 0 15px;
}

.category-card-title-container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5rem;
  flex: 2;
}

.category-card:hover {
  transform: translateY(-10px);
}

.category-image {
  aspect-ratio: 1/1;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.category-card-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0.5rem;
  text-transform: uppercase;
}

.category-card-title-container h4 {
  font-family: "poppins";
  font-size: 0.875rem;
  font-weight: 700;
}

.category-card-title-container p {
  margin-top: auto;
}

/***** Recipes *****/

.recipes-container {
  display: grid;
  gap: 2rem 0.5rem;
  height: 100%;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  width: 100%;
  background-color: rgba(230, 235, 224, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding: 2rem;
  border-radius: 15px;
  justify-items: center;
}

/***** Recipe Card *****/

.recipe-card {
  background-color: var(--background);
  padding: 0.5rem;
  box-shadow: var(--bs);
  max-width: 400px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.share {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 20px;
  width: 20px;
}

.title {
  text-align: center;
  font-size: var(--fs-800);
  font-weight: bold;
  text-transform: capitalize;
}

.recipe-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

/***** Single Recipe *****/
.single-recipe-card {
  margin-bottom: 4rem;
  position: relative;
  max-width: 80%;
  color: white;
}

.single-recipe-header {
  background-color: rgba(230, 235, 224, 0.2);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: brightness(40%) blur(4px);
  border-radius: 15px;
  box-shadow: var(--bs);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  width: 100%;
  margin: auto;
}

.single-notes {
  line-height: 1.5;
}

.single-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.single-icons {
  display: flex;
  justify-content: center;
  color: white;
  margin: 10px 0 40px;
}

.single-icons > * {
  margin-left: 0.5rem;
}

.single-share {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.single-edit {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.single-like-true {
  height: 30px;
  width: 30px;
  color: var(--secondary-dark);
  cursor: pointer;
}

.single-like-false {
  right: 60px;
  top: 20px;
  height: 30px;
  width: 30px;
  color: white;
  cursor: pointer;
}

.single-title {
  text-align: center;
  font-size: var(--fs-800);
  text-transform: capitalize;
  margin: 0;
}

.subtitle {
  font-size: var(--fs-900);
  margin-bottom: 1rem;
}

.single-ingredients-container,
.single-steps-container {
  background-color: rgba(230, 235, 224, 0.2);
  box-shadow: var(--bs);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: brightness(40%) blur(4px);
  border-radius: 15px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.single-ingredients > * {
  margin-bottom: 0.5rem;
}

.single-recipe > li {
  margin-bottom: 0.5rem;
  line-height: 1.75;
}

.single-recipe li::first-letter,
.single-ingredients li::first-letter {
  text-transform: capitalize;
}

.created-by {
  font-size: var(--fs-1000);
  text-align: center;
  color: white;
}

.tags-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
}

.tags-container button {
  background-color: var(--secondary-dark);
}
.tags-container button:hover {
  transform: scale(1);
  cursor: default;
  opacity: 1;
}

.single-recipe-image-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  justify-items: center;
}

.single-recipe-img-container {
  height: 300px;
  width: 300px;
  border-radius: 15px;
  box-shadow: var(--bs);
  overflow: hidden;
  cursor: pointer;
}

.single-recipe-img-container:hover {
  transform: scale(2);
}

.single-recipe-img-container img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.add-photos-button {
  width: 80%;
}

/***** Login *****/
.form-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.form-container {
  background-color: rgba(230, 235, 224, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: var(--bs);
  width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.form-container h1 {
  margin-bottom: 2rem;
  text-align: center;
}

.form-container p {
  text-align: center;
}

.edit-recipe-tags-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.tag-btn {
  padding: 0.5rem 0.5rem;
  border-radius: 25px;
}

.tag-btn-active {
  padding: 0.5rem 0.5rem;
  border-radius: 25px;
  background-color: var(--secondary-dark);
}

.form {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.form label {
  margin-bottom: 0.5rem;
}

.submit-btn {
  margin: 2rem 0;
  letter-spacing: 1px;
  background-color: var(--secondary-dark);
  font-weight: bold;
}

.google-btn {
  padding: 0.7em 2em 0.5em;
}

.google-btn img {
  height: 50px;
  margin-right: 20px;
}

.form-link {
  text-align: center;
  margin-bottom: 0.5rem;
}

.input {
  font-family: inherit;
  padding: 1rem;
  border-radius: 5px;
  border: none;
  margin-bottom: 1rem;
  font-size: var(--fs-1000);
}

.input:focus {
  outline: none;
  border: 1px solid var(--primary) inset;
  color: var(--primary);
}

.input:focus::-webkit-input-placeholder {
  color: var(--primary);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding-left: 4px;
}

.input:focus::-moz-placeholder {
  color: var(--primary);
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding-left: 4px;
}

.input:focus:-ms-input-placeholder {
  color: var(--primary);
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding-left: 4px;
}

.input:focus::-ms-input-placeholder {
  color: var(--primary);
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding-left: 4px;
}

.input:focus::placeholder {
  color: var(--primary);
  transition: all 0.3s ease;
  padding-left: 4px;
}

.add-recipe-category {
  cursor: pointer;
}

.add-recipe-category option {
  cursor: pointer;
  font-family: inherit;
}

/***** Profile *****/
.profile-container {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

  width: 100%;
}

.account-info-container {
  background-color: rgba(230, 235, 224, 0.5);
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding-top: 2rem;
  grid-row: 1/2;
  box-shadow: var(--bs);
  border-radius: 15px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.account-info {
  padding: 2rem;
  width: 100%;
  display: grid;
}

.account-info h4 {
  margin-bottom: 0.5rem;
}

.name-input {
  width: 100%;
  position: relative;
}
.name-input input {
  width: 100%;
}

.md-person {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 10px;
  right: 10px;
  color: white;
}

.email-input {
  width: 100%;
  position: relative;
}
.email-input input {
  width: 100%;
}

.md-email {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 10px;
  right: 10px;
  color: white;
}

.favorites-container {
  background-color: rgba(230, 235, 224, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: var(--bs);
  border-radius: 15px;
  text-align: center;
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.favorites-grid {
  display: grid;
  margin: 2rem 0;
  gap: 2rem;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  width: 100%;
}

.profile-recipes-container {
  background-color: rgba(230, 235, 224, 0.5);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  padding-top: 2rem;
  box-shadow: var(--bs);
  border-radius: 15px;
  height: 100%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.profile-btn {
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
}

.profile-delete-button {
  color: var(--secondary-dark);
  background-color: white;
  padding: 0.25rem;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: absolute;
  top: -15px;
  right: -10px;
}

/***** Add Recipe *****/
.instructions {
  font-size: var(--fs-1200);
  text-align: left;
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.recipe-input {
  font-family: inherit;
  padding: 1rem;
  border-radius: 5px;
  border: none;
  margin-bottom: 0.5rem;
  font-size: var(--fs-1200);
}

.input-margin {
  margin-bottom: 2rem;
}

.recipe-input:focus {
  outline: none;
  color: var(--primary);
}

/**** Media Queries ****/

@media screen and (max-width: 899px) {
  .mobile-menu-container {
    position: fixed;
    right: 0px;
    top: 0px;
    justify-content: flex-start;
    padding-top: 8rem;
  }

  .page-title {
    margin: 1rem 0;
  }

  /***** Navbar *****/
  .nav-container {
    padding: 2rem;
    font-size: var(--fs-900);
  }

  .logo {
    color: white;
  }

  .search {
    padding: 1rem 1rem;
    border-radius: 5px;
    border: none;
    font-family: inherit;
    width: 100%;
    box-shadow: var(--bs);
    position: relative;
  }

  .search-icon {
    position: absolute;
    right: 7px;
    top: 10px;
    color: var(--primary-light);
    height: 30px;
    width: 30px;
  }

  .search-container {
    width: 90%;
  }

  /***** Menu *****/
  .menu-active {
    background-color: var(--secondary);
    box-shadow: var(--bs);
    padding: 4rem 1rem;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: fixed;
    z-index: 5;
    opacity: 1;
    transform: translateY(-4.5rem);
    transition: opacity 0.15s ease, transform 0.15s ease;
    top: 4.5rem;
    right: 0;
    height: 100vh;
    width: 100vw;
    align-items: center;
  }

  .menu {
    opacity: 0;
    transform: translateY(-5.5rem);
    transition: opacity 0.5s ease, transform 0.5s ease, display 0.5s ease;
    background-color: var(--secondary);
    box-shadow: var(--bs);
    padding: 2rem;
    border-radius: 15px;
    display: none;
    flex-direction: column;
    gap: 1rem;
    z-index: 5;
  }

  .menu-icon {
    cursor: pointer;
    position: relative;
  }

  .close-menu-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  .menu-item {
    text-align: left;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: var(--fs-600);
  }

  .menu-item:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .menu-item::after {
    content: "";
    display: inline-block;
    width: 100%;
    border: solid 1px black;
    background-color: black;
    position: absolute;
    left: 0;
    bottom: -1px;
    transform: scaleX(0);
    transform-origin: right;
    transition: all 0.5s ease;
    border-radius: 10%;
  }

  .menu-item:hover::after {
    transform: scaleX(1);
  }

  /***** Main Page *****/
  .header-container {
    padding-bottom: 4rem;
  }

  .header-content h1 {
    font-size: var(--fs-400);
    text-transform: uppercase;
    line-height: 6rem;
  }

  .header-content p {
    margin-bottom: 4rem;
  }

  .category-card,
  .ais-Hits-item {
    flex-direction: row;
    font-size: 10px;
    height: 4rem;
  }

  .search-card {
    flex-direction: row;
    font-size: 10px;
    height: 4rem;
    width: 100%;
  }

  .ais-Hits-item:hover {
    transform: translateY(0);
  }

  .category-image {
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .category-card-title-container,
  .search-card-title-container {
    flex: 2;
  }

  .category-cards-container,
  .recipes-cards-container {
    gap: 1rem;
  }

  .section-btn-container {
    display: flex;
    justify-content: center;
    margin: 2rem 0 0 0;
  }

  .section-btn {
    width: 100%;
  }

  .icon {
    height: var(--fs-900);
    width: 20px;
  }

  .header-content {
    width: 100%;
    margin: 4rem auto 2rem;
  }

  .main-title {
    width: 100%;
    text-align: center;
    line-height: 4rem;
  }

  .kitchen {
    padding: 0;
    text-align: center;
  }

  .category-section-container,
  .recipes-section-container {
    padding: 1rem;
  }

  .category-section-title,
  .recipes-section-title {
    position: relative;
    top: 0;
    left: 0;
    font-size: var(--fs-600);
    font-family: var(--font);
    text-align: center;
    margin-bottom: 1rem;
  }

  .page-container {
    padding: 0;
  }

  .container {
    padding: 1rem;
  }

  .single-recipe-card {
    width: 100%;
  }

  /***** Filter *****/
  .filter-container {
    margin-bottom: 1rem;
    gap: 0.5rem;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);

    padding: 0.5rem;
    padding-right: 0;
  }

  .filter-container button {
    background-color: rgba(230, 235, 224, 0.5);

    padding: 0.5rem 1rem;
    border-radius: 40px;
    margin-left: 0;
    font-size: var(--fs-1000);
  }

  /***** Profile *****/

  .profile-container {
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .account-info-container {
    padding-top: 1rem;
  }

  .account-info {
    padding: 1rem;
  }

  .account-info h4 {
    margin-bottom: 0.25rem;
  }

  .favorites-container {
    padding: 1rem;
  }

  .favorites-grid {
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 1rem;
  }

  .favorites-grid > * {
    width: 100%;
  }

  .category-card-mobile,
  .ais-Hits-item {
    width: 100%;
  }

  .profile-recipes-container {
    padding-top: 1rem;
    padding: 1rem;
    gap: 1rem;
  }

  .profile-btn {
    width: 100%;
    margin-top: auto;
    margin-bottom: 0;
  }

  .profile-delete-button {
    color: var(--secondary-dark);
    background-color: white;
    padding: 0.25rem;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: absolute;
    top: -15px;
    right: -10px;
  }

  .form-page-container {
    margin: 1rem auto;
  }

  .form-container {
    padding: 1rem;
    margin: auto;
    margin-bottom: 1rem;
    width: 100%;
  }

  .form-container h1 {
    margin-bottom: 1rem;
  }

  .form {
    width: 100%;
  }

  .single-recipe-card {
    max-width: 100%;
    margin-bottom: 0;
    margin-top: 1rem;
    padding: 0 10px;
  }

  .single-icons {
    margin-bottom: 1rem;
  }

  .single-card-details {
    padding: 1rem;
    gap: 1rem;
  }

  .single-title {
    margin-bottom: 0;
  }

  .subtitle {
    text-align: center;
  }

  .recipes-container,
  .ais-Hits-list,
  .categories-container,
  .category-container {
    padding: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .recipes-container > * {
    width: 100%;
  }

  .ais-Hits {
    margin: 0;
    margin-top: 10px;
  }

  .category-card,
  .ais-Hits-item {
    width: 100%;
  }

  .input-margin {
    margin-bottom: 1rem;
  }

  .instructions {
    margin-bottom: 1rem;
  }

  .edit-recipe-tags-container {
    margin-bottom: 1rem;
  }

  .submit-btn {
    margin: 1rem 0;
  }
}

@media screen and (max-width: 400px) {
  .btn-container {
    flex-direction: column;
    gap: 1rem;
  }

  .header-content h1 {
    line-height: 4rem;
  }
  .mobile-menu-container {
    position: fixed;
    left: 0px;
    top: 0px;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    width: 100%;
  }

  .header-content {
    width: 100%;
    margin: 30% auto 2rem;
  }

  .form-container {
    width: 100%;
  }

  .search-bar-container {
    width: 90%;
  }

  .search-bar-container input {
    width: 100%;
    height: 60px;
    padding-left: 20px;
  }

  .search-bar-container button {
    width: 30%;
  }

  .mobile-menu-item p {
    padding-left: 0;
    gap: 10px;
    margin-left: 0;
    width: 100%;
  }
}
